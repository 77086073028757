@import 'components/global.scss';

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  background: black;
  background: rgba(0, 0, 0, 0.7);

  .modal-content {
    position: absolute;
    left: 50%;
    width: 94%;
    top: 55%;
    opacity: 0;
    text-align: left;
    // max-height: $scale17;
    transform: translate(-50%, -50%);
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
}

.modal-appear {
  opacity: 0;
}

.modal-appear-done {
  opacity: 1;

  .modal-content {
    opacity: 1;
    top: 50%;
  }
}
